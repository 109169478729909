import React from 'react';
import Layout from '../layouts/Layout';

const HomeTemplate = data => {
  const {pageContext: {lang}} = data

  return (
    <Layout>
      <tm-page className="theme--salesforce">
        <tm-hero-a 
          background="light" 
          cta-href={`/${lang}/trailblazerconnect/workforce-partners/apply`}
          cta-label="Apply Here" 
          img-align="bottom" 
          img-alt="Trailblazer Workforce Partner Program" 
          img-src="https://developer.salesforce.com/resources2/wfp/Partner-Hero-V1.png" 
          subtitle="Be part of a global movement to develop the next generation of Trailblazers." 
          title="Trailblazer Workforce Partner Program" />
        
        <tm-section-a 
          description="According to IDC, the Salesforce economy is set to create 9.3 million new jobs by 2026. We help training, non-profit, and higher education organizations teach Salesforce skills in their communities and classrooms, so more Trailblazers can pursue technology, sales, service, and marketing jobs in the Salesforce ecosystem." 
          title="Teach Salesforce in Your Program" />

        <tm-section-a>
          <tm-promo-b 
            background="light" 
            cta-href="https://trailhead.salesforce.com/trailblazerconnect/directory" 
            cta-title="Browse Directory" 
            description="Search for organizations teaching Salesforce skills around the world." 
            img-src="https://developer.salesforce.com/resources2/wfp/Partner-Banner.png" 
            title="Trailblazer Workforce Partner Directory" />
        </tm-section-a>

        <tm-section-a title="Workforce Partner Program Benefits">
          <tm-card-grid-c img-aspect="60%">
            <tm-grid-item 
              description="Get exclusive access to turnkey content, curriculum, trailmixes, certification vouchers, and more." 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile1.png" 
              imgp-alt="" 
              pretitle="" 
              title="Content and Resources" />
            
            <tm-grid-item 
              description="Collaborate with other Workforce Development organizations and employers, get support, and share best practices." 
              img-alt="" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile2.png" 
              pretitle="" 
              title="Vibrant Community" />
            
            <tm-grid-item 
              description="Scale your reach with a marketing kit and feature in the Workforce Partner Directory to connect with individuals ready to skill up and employers hiring Salesforce talent." 
              img-alt="" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile3.png" 
              pretitle="" 
              title="Marketing Support" />
          </tm-card-grid-c>
        </tm-section-a>

        <tm-section-a 
          cta-href={`/${lang}/trailblazerconnect/workforce-partners/apply`}
          cta-title="Apply Here" 
          title="How To Join">
          
          <tm-card-grid-a img-aspect="60%" img-type="left">
            <tm-grid-item 
              description="Share more information about your program and how you plan to incorporate Salesforce and Trailhead training." 
              img-alt="Number One" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile4.png" 
              title="Submit Your Application" />
            
            <tm-grid-item 
              description="If accepted, you'll get access to our Learning Center, Partner Directory, Program Community, ecosystem updates, and quarterly events." 
              img-alt="Number two" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile5.png" 
              title="Access Program Benefits" />
              
            <tm-grid-item 
              description="Continue to track cohort metrics and share your success stories." 
              img-alt="Number three" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile6.png" 
              title="Share Your Impact" />
          </tm-card-grid-a>
        </tm-section-a>

        <tm-section-a 
          cta-href="http://sforce.co/WorkforceFAQ" 
          cta-target="true" 
          cta-title="Read FAQ" 
          description="Training, non-profit, and higher education organizations preparing job seekers for jobs in the Salesforce ecosystem are eligible to apply. See FAQ for full requirements." 
          title="Workforce Partner Requirements" />

        <tm-section-a title="Workforce Partner Spotlights">
          <tm-quotes-a>
            <tm-quote 
              author="Kyle O'Brien" 
              img-alt="Kyle O'Brien image" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Photo1-Kyle.png" 
              role="Director - Revolent APAC" 
              text="Thanks to the Workforce Partner Programteam, we deliver high quality training programs and fully certified Salesforce talent for to employers across Asia-Pacific." />
            
            <tm-quote 
              author="Gaspar Rodriguez" 
              img-alt="Gaspar Rodriguez image" 
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Photo2-Gaspar.png" 
              role="Co-Founder and Director - RefugeeForce" 
              text="The Workforce Partner Program helps us upskill and reskill refugees who are entirely new to tech or business, preparing them for in-demand jobs." />
          </tm-quotes-a>
        </tm-section-a>
        
        <tm-promo-c 
          background="light" 
          description="Are you an employer looking to connect with Salesforce-skilled talent? Salesforce Talent Alliance connects new Salesforce professionals with employers ready to hire. Pledge to build a diverse workforce and join today. " 
          img-src="https://developer.salesforce.com/resources2/wfp/TalentAlliance.png" 
          primary-cta-href="http://sforce.co/TalentAlliance" 
          primary-cta-title="Join Now" />
          
        <tm-section-a>
          <tm-end-cap-a 
            background="dark" 
            cta-href="mailto:trailblazerworkforcepartner@salesforce.com?subject=Trailblazer%20Workforce%20Partner%20[Website]" 
            cta-label="Contact Us" 
            img-alt="backpack image" 
            img-src="https://developer.salesforce.com/resources2/wfp/Partner-Endcap.png" 
            subtitle="Reach out to the Workforce Partner team for questions and support." 
            title="Get In Touch" />
        </tm-section-a>

      </tm-page>
      
      <th-element-loader element="tm-page" style={{height: '100vh'}} />
    </Layout>
  );
};

export default HomeTemplate;
